<template>
  <div>
    <b-row>
      <b-col
        md="4"
        sm="12"
      >
        <b-form-group
          label="Atanan Yetkili 1"
          label-for="user_1"
        >
          <validation-provider
            #default="{ errors }"
            name="Atanan Yetkili"
            rules="required"
          >
            <v-select
              id="user_1"
              v-model="crmRequest.id_com_user1"
              label="name"
              :reduce="type => type.id"
              :options="users"
              placeholder="Seçiniz"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="4"
        sm="12"
      >
        <b-form-group
          label="Atanan Yetkili 2"
          label-for="user_2"
        >
          <v-select
            id="user_2"
            v-model="crmRequest.id_com_user2"
            label="name"
            :reduce="type => type.id"
            :options="users"
            placeholder="Seçiniz"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="4"
        sm="12"
      >
        <b-form-group
          label="Atanan Yetkili 3"
          label-for="user_3"
        >
          <v-select
            id="user_3"
            v-model="crmRequest.id_com_user3"
            label="name"
            :reduce="type => type.id"
            :options="users"
            placeholder="Seçiniz"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="12"
      >
        <b-form-group
          label="Onay Yetkilisi"
          label-for="apply_user"
        >
          <validation-provider
            #default="{ errors }"
            name="Onay Yetkilisi"
            rules="required"
          >
            <v-select
              id="apply_user"
              v-model="crmRequest.id_com_confirmation_user"
              label="name"
              :reduce="type => type.id"
              :options="users"
              placeholder="Seçiniz"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Users',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  created() {
    this.getUsers()
    localize(this.locale)
  },
  methods: {
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: ['com_user.id AS id', 'com_user.name AS name'],
        where: {
          'com_user.status': 1,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
