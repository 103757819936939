<template>
  <b-form-group
    label="Lokasyon"
    label-for="location"
  >
    <validation-provider
      #default="{ errors }"
      name="Lokasyon"
      rules="required"
    >
      <v-select
        id="location"
        v-model="crmRequest.id_com_location"
        label="title"
        :reduce="type => type.id"
        :options="locations"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Locations',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
  },
  created() {
    this.getLocations()
    localize(this.locale)
  },
  methods: {
    getLocations() {
      this.$store.dispatch('locations/locationsList')
    },
  },
}
</script>

<style scoped>

</style>
