<template>
  <b-form-group
    label="Başvuru Metni"
    for="apply_text"
  >
    <validation-provider
      #default="{ errors }"
      name="Başvuru Metni"
    >
      <b-form-textarea
        v-model="crmRequest.content"
        rows="3"
        hidden
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
    <quill-editor
      id="apply_text"
      v-model="crmRequest.content"
      :options="editorOption"
      class="bg-white text-black-50"
    >
      <div
        id="toolbar"
        slot="toolbar"
      >
        <!-- Add a bold button -->
        <button class="ql-bold">
          Bold
        </button>
        <button class="ql-italic">
          Italic
        </button>
        <button
          class="ql-list"
          value="ordered"
        />
        <!-- Add font size dropdown -->
        <select class="ql-size">
          <option value="small" />
          <!-- Note a missing, thus falsy value, is used to reset to default -->
          <option selected />
          <option value="large" />
          <option value="huge" />
        </select>
        <select class="ql-font">
          <option selected="selected" />
          <option value="serif" />
          <option value="monospace" />
        </select>
        <!-- Add subscript and superscript buttons -->
        <button
          class="ql-script"
          value="sub"
        />
        <button
          class="ql-script"
          value="super"
        />
      </div>
    </quill-editor>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
// eslint-disable-next-line import/order
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'ApplyText',
  components: {
    BFormGroup,
    BFormTextarea,
    quillEditor,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
        theme: 'snow',
      },
    }
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped>

</style>
