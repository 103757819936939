<template>
  <b-row v-if="id_com_cars">
    <b-col>
      <b-card title="Araç Kartı">
        <b-list-group>
          <template v-if="car.brand && car.model">
            <list-item
              v-if="car.brand"
              title="Marka"
              :value="car.brand"
            />
            <list-item
              v-if="car.model"
              title="Model"
              :value="car.model"
            />
          </template>
          <template v-else>
            <list-item
              v-if="car.swap_brand"
              title="Marka"
              :value="car.swap_brand"
            />
            <list-item
              v-if="car.swap_model"
              title="Model"
              :value="car.swap_model"
            />
          </template>
          <list-item
            v-if="car.hardware"
            title="Donanım Paketi"
            :value="car.hardware"
          />
          <list-item
            v-if="car.myear"
            title="Model Yılı"
            :value="car.myear"
          />
          <list-item
            v-if="car.color"
            title="Renk"
            :value="car.color"
          />
          <list-item
            v-if="car.fuel"
            title="Yakıt Tipi"
            :value="car.fuel"
          />
          <list-item
            v-if="car.gear"
            title="Vites Tipi"
            :value="car.gear"
          />
          <list-item
            v-if="car.license_plate"
            title="Plaka"
            :value="car.license_plate"
          />
        </b-list-group>
        <div
          v-if="id_com_cars"
          class="mt-2 text-center"
        >
          <b-button
            variant="primary"
            :to="'/cars/edit/' + id_com_cars"
          >
            Bilgileri Güncelle
          </b-button>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BListGroup,
  BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ListItem from '@/views/Crm/View/ListItem'

export default {
  name: 'CustomersView',
  components: {
    BRow,
    BCol,
    BCard,
    BListGroup,
    BButton,
    ListItem,
  },
  props: {
    id_com_cars: {
      type: String,
    },
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
  watch: {
    id_com_cars(val) {
      if (val) {
        this.getCar()
      } else {
        this.$store.dispatch('cars/carReset')
      }
    },
  },
  created() {
    this.getCar()
  },
  methods: {
    getCar() {
      if (this.id_com_cars) {
        this.$store.dispatch('cars/carView', this.id_com_cars)
      }
    },
  },
}
</script>
