<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Departman 1"
          label-for="departmant_1"
        >
          <validation-provider
            #default="{ errors }"
            name="Departman"
            rules="required"
          >
            <v-select
              id="departmant_1"
              v-model="crmRequest.id_com_department1"
              label="title"
              :reduce="type => type.id"
              :options="departments"
              placeholder="Seçiniz"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Departman 2"
          label-for="departmant_2"
        >
          <v-select
            id="departmant_2"
            v-model="crmRequest.id_com_department2"
            label="title"
            :reduce="type => type.id"
            :options="departments"
            placeholder="Seçiniz"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Departments',
  components: {
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
    departments() {
      return this.$store.getters['departments/getDepartments']
    },
  },
  created() {
    this.getDepartments()
    localize(this.locale)
  },
  methods: {
    getDepartments() {
      this.$store.dispatch('departments/departmentsList')
    },
  },
}
</script>

<style scoped>

</style>
