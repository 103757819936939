<template>
  <div>
    <b-row>
      <b-col
        md="4"
        sm="12"
      >
        <customer-card
          v-if="crmRequest.id_com_customer"
          :id_com_customer="crmRequest.id_com_customer"
        />
        <car-card
          v-if="crmRequest.id_com_cars"
          :id_com_cars="crmRequest.id_com_cars"
        />
        <e-mails :email-users="crmRequest.email_users" />
      </b-col>
      <b-col
        md="8"
        sm="12"
      >
        <edit-apply :id_com_customer="crmRequest.id_com_customer" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCol, BRow } from 'bootstrap-vue'
import CustomerCard from '@/views/Crm/components/CustomerCard.vue'
import CarCard from '@/views/Crm/components/CarCard.vue'
import EditApply from '@/views/Crm/components/EditApply.vue'
import EMails from '@/views/Crm/components/EMailsEdit.vue'

export default {
  name: 'Edit',
  components: {
    BRow,
    BCol,
    CustomerCard,
    CarCard,
    EditApply,
    EMails,
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
  },
  created() {
    this.getRequest()
  },
  methods: {
    getRequest() {
      this.$store.dispatch('crmRequests/requestView', this.$route.params.id)
        .then(response => {
          if (response.id_com_customer) {
            this.$store.dispatch('customers/customerView', response.id_com_customer)
          }
        })
    },
  },
}
</script>
