<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Dosya Yükle</b-card-title>
    </b-card-header>
    <b-card-body>
      <pre-loading v-if="uploading" />
      <template v-else>
        <b-form-file
          v-model="dataItem.upload_file"
          placeholder="Bir dosya seçin veya buraya bırakın..."
          drop-placeholder="Dosya buraya bırakın..."
          browse-text="Dosya Seçin"
        />
        <div
          v-if="dataItem.upload_file"
          class="text-center mt-1"
        >
          <b-button
            variant="success"
            @click="uploadFile"
          >
            <FeatherIcon icon="UploadIcon" />
            Dosya Yükle
          </b-button>
        </div>
      </template>
    </b-card-body>
    <b-table-simple v-if="crmRequest.files.length > 0">
      <b-thead>
        <b-tr>
          <b-th colspan="2">
            Yüklenen Dosyalar
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(file,index) in crmRequest.files"
          :key="file.id"
        >
          <b-td>
            <b-link
              :href="baseURL + '/media/crm/' + file.filename"
              target="_blank"
            >
              <FeatherIcon icon="PaperclipIcon" /> Dosya Eki {{ index + 1 }}
            </b-link>
          </b-td>
          <b-td class="width-100 text-right">
            <b-button
              variant="flat-danger"
              class="btn-icon"
              pill
              @click="removeData(file.id)"
            >
              <FeatherIcon icon="TrashIcon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BFormFile, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BButton, BLink,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'FileUpload',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormFile,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
    BLink,
    PreLoading,
  },
  data() {
    return {
      upload_file: null,
      baseURL: this.$store.state.app.baseURL,
    }
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
    dataItem() {
      return this.$store.getters['crmRequestFiles/dataItem']
    },
    uploading() {
      return this.$store.getters['crmRequestFiles/uploading']
    },
  },
  methods: {
    removeData(id, key) {
      this.$store.dispatch('crmRequestFiles/removeData', id)
        .then(response => {
          if (response) {
            this.crmRequest.files.splice(key, 1)
          }
        })
    },
    uploadFile() {
      this.$store.dispatch('crmRequestFiles/saveData', {
        id_com_crm: this.crmRequest.id,
        upload_file: this.dataItem.upload_file,
      })
        .then(response => {
          if (response.status) {
            this.crmRequest.files.push({
              id: response.id,
              filename: response.filename,
            })
            this.dataItem.upload_file = null
          }
        })
    },
  },
}
</script>
