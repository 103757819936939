<template>
  <b-row>
    <b-col>
      <div
        v-if="!id_com_customer"
        class="text-center"
      >
        <b-spinner />
      </div>
      <b-card
        v-else
        title="Müşteri Kartı"
      >
        <b-list-group>
          <list-item
            title="Müşteri Tipi"
            :value="customer.customer_type"
          />
          <list-item
            title="Adı Soyadı"
            :value="customer.name"
          />
          <list-item
            title="Firma Adı"
            :value="customer.company_name"
          />
          <list-item
            title="Telefon"
            :value="customer.phone"
          />
          <list-item
            title="Telefon 2"
            :value="customer.phone2"
          />
          <list-item
            title="E-Posta"
            :value="customer.email"
          />
          <list-item
            title="Müşteri Notu"
            :value="customer.notes"
          />
          <list-item
            title="TC Kimlik No"
            :value="customer.tckn"
          />
          <list-item
            title="Vergi No"
            :value="customer.tax_number"
          />
          <list-item
            title="Vergi Dairesi"
            :value="customer.tax_office"
          />
          <list-item
            title="Adres"
            :value="addressLine"
          />
          <list-item
            title="Sektör"
            :value="customer.sector"
          />
          <list-item
            title="Meslek"
            :value="customer.job"
          />
          <list-item
            title="Temas Tipi"
            :value="customer.meet"
          />
          <list-item
            title="KVKK"
            :value="kvkk"
          />
        </b-list-group>
        <div class="mt-2 text-center">
          <b-button
            variant="primary"
            :to="'/customers/edit/' + customer.id"
          >
            Bilgileri Güncelle
          </b-button>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BListGroup,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import ListItem from '@/views/Customers/view/ListItem.vue'

export default {
  name: 'CustomersView',
  components: {
    BRow,
    BCol,
    BCard,
    BListGroup,
    BButton,
    BSpinner,
    ListItem,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    id_com_customer: {
      type: String,
      required: false,
    },
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    addressLine() {
      const addon = []
      if (this.customer.address) {
        addon.push(this.customer.address)
      }
      if (this.customer.city) {
        addon.push(this.customer.city)
      }
      if (this.customer.district) {
        addon.push(this.customer.district)
      }
      if (this.customer.region) {
        addon.push(this.customer.region)
      }
      if (this.customer.neighborhood) {
        addon.push(this.customer.neighborhood)
      }
      return addon.join('/')
    },
    kvkk() {
      if (this.customer.kvkk) {
        return `${this.customer.kvkk_type} - ${this.moment(this.customer.kvkkdate).format('DD.MM.YYYY')}`
      }
      return 'Yok'
    },
  },
  mounted() {
    this.getCustomer()
  },
  methods: {
    async getCustomer() {
      const id = this.id_com_customer
      if (id) {
        await this.$store.dispatch('customers/customerView', id)
      }
    },
  },
}
</script>
