<template>
  <b-form-group
    label="Başvuru Kaynağı"
    label-for="apply_source"
  >
    <validation-provider
      #default="{ errors }"
      name="Başvuru Kaynağı"
      rules="required"
    >
      <v-select
        id="apply_source"
        v-model="crmRequest.id_com_crm_applysource"
        label="title"
        :reduce="type => type.id"
        :options="crmApplySources"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'ApplySource',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
    crmApplySources() {
      return this.$store.getters['crmApplySources/getCrm_applysources']
    },
  },
  created() {
    this.getCrmApplySources()
    localize(this.locale)
  },
  methods: {
    getCrmApplySources() {
      this.$store.dispatch('crmApplySources/crm_applysourcesList')
    },
  },
}
</script>

<style scoped>

</style>
