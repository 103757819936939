<template>
  <b-form-group
    label="Önem Derecesi"
    label-for="id_com_crm_level"
  >
    <validation-provider
      #default="{ errors }"
      name="Önem Derecesi"
      rules="required"
    >
      <v-select
        id="id_com_crm_level"
        v-model="crmRequest.id_com_crm_level"
        label="title"
        :reduce="type => type.id"
        :options="crmLevels"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'ImportanceLevel',
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
    crmLevels() {
      return this.$store.getters['crmLevels/getCrm_levels']
    },
  },
  created() {
    this.getCrmLevels()
    localize(this.locale)
  },
  methods: {
    checkBoxClass(id) {
      let checkboxClass = 'custom-control-primary'
      if (id === '1') {
        checkboxClass = 'custom-control-success'
      }
      if (id === '2') {
        checkboxClass = 'custom-control-primary'
      }
      if (id === '3') {
        checkboxClass = 'custom-control-warning'
      }
      if (id === '4') {
        checkboxClass = 'custom-control-danger'
      }
      return checkboxClass
    },
    getCrmLevels() {
      this.$store.dispatch('crmLevels/crm_levelsList', {
        select: [
          'com_crm_level.id AS id',
          'com_crm_level.title AS title',
        ],
      })
    },
  },
}
</script>

<style scoped>

</style>
