<template>
  <div>
    <b-row>
      <b-col
        md="4"
        sm="12"
      >
        <b-form-group
          label="Konu 1"
          label-for="subject_1"
        >
          <validation-provider
            #default="{ errors }"
            name="Konu"
            rules="required"
          >
            <v-select
              id="subject_1"
              v-model="crmRequest.id_com_crm_subject1"
              label="title"
              :reduce="type => type.id"
              :options="subjects"
              placeholder="Seçiniz"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        md="4"
        sm="12"
      >
        <b-form-group
          label="Konu 2"
          label-for="subject_2"
        >
          <v-select
            id="subject_2"
            v-model="crmRequest.id_com_crm_subject2"
            label="title"
            :reduce="type => type.id"
            :options="subjects"
            placeholder="Seçiniz"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="4"
        sm="12"
      >
        <b-form-group
          label="Konu 3"
          label-for="subject_3"
        >
          <v-select
            id="subject_3"
            v-model="crmRequest.id_com_crm_subject3"
            label="title"
            :reduce="type => type.id"
            :options="subjects"
            placeholder="Seçiniz"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Subjects',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
    subjects() {
      return this.$store.getters['crmSubjects/getCrm_subjects']
    },
  },
  created() {
    this.getCrmSubjects()
    localize(this.locale)
  },
  methods: {
    getCrmSubjects() {
      this.$store.dispatch('crmSubjects/crm_subjectsList')
    },
  },
}
</script>

<style scoped>

</style>
