<template>
  <div>
    <b-form-group
      label="Araç Kartı"
      label-for="id_com_cars"
    >
      <v-select
        id="id_com_cars"
        v-model="crmRequest.id_com_cars"
        :options="cars"
        placeholder="Araç Kartı"
        :reduce="car => car.id"
      >
        <template v-slot:option="option">
          <div v-if="option.brand && option.model">
            {{ option.brand }} {{ option.model }}
          </div>
          <div v-else>
            {{ option.swap_brand }} {{ option.swap_model }}
          </div>
          <div>
            <small class="text-danger">{{ option.myear }}</small>
          </div>
          <div>
            <small class="text-warning">{{ option.license_plate }}</small>
          </div>
        </template>
        <template v-slot:selected-option="option">
          <div v-if="option.brand && option.model">
            {{ option.brand }} {{ option.model }} <span class="text-primary">{{ option.license_plate }}</span>
          </div>
          <div v-else>
            {{ option.swap_brand }} {{ option.swap_model }} <span class="text-primary">{{ option.license_plate }}</span>
          </div>
        </template>
      </v-select>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Cars',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
    cars() {
      return this.$store.getters['cars/getCars']
    },
  },
}
</script>

<style scoped></style>
